<template>
  <div>

    
    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <h2 class="text-2xl font-bold">Expense Requests</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
            placeholder="Search for employee’s name, status, etc."
            v-model="searchQuery">
          <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="requests.data"
        :columns="requests.columns"
        :query="searchQuery"
        :loading="requests.loading"
        :onClick="selectRequest"
        ref="table"
      />
    </div>

    <modal className="w-full md:w-3/5 xl:w-4/10" ref="modal">

      <h1 class="text-2xl font-bold mb-10">Review Expense Request</h1>

      <div v-if="request">
        
        <div class="mb-10">
          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Staff Name</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="person-outline" class="text-lg mr-2"></ion-icon>
                {{ request.user.name }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Amount</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
                {{ request.amount | currency }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Expense Label</div>
              <div class="text-gray-500 text-sm flex flex-row items-start">
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ request.label }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Description</div>
              <div class="text-gray-500 text-sm flex flex-row items-start">
                <ion-icon name="bar-chart-outline" class="text-lg mr-2"></ion-icon>
                {{ request.description }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Account Type</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ request.company_account.type }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Account State</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
                {{ request.company_account.status }}
              </div>
            </div>
          </div>

          <template v-if="request.company_account.type == 'debit'">
            <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">Account Debit Balance</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                  {{ request.company_account.balance }}
                </div>
              </div>
              <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
                <!-- <div class="opacity-75 text-sm font-medium mb-1">Account State</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
                  {{ request.company_account.status }}
                </div> -->
              </div>
            </div>
          </template>
          <template v-else-if="request.company_account.type == 'credit'">
            <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
              <div class="col-span-1 py-4">
                <div class="opacity-75 text-sm font-medium mb-1">Account Credit Balance</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                  {{ request.company_account.available_credit }}
                </div>
              </div>
              <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
                <div class="opacity-75 text-sm font-medium mb-1">Account Credit Limit</div>
                <div class="text-gray-500 text-sm flex flex-row items-center">
                  <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
                  {{ request.company_account.credit_limit }}
                </div>
              </div>
            </div>
          </template>

          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Expense Category</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
                {{ request.company_account.type }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Account State</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
                {{ request.company_account.status }}
              </div>
            </div>
          </div>

        </div>

        <template v-if="getFormError(declineForm) || getFormError(approveForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(declineForm) || getFormError(approveForm) }}</span>
          </div>
        </template>

        <div class="flex items-center justify-center flex-wrap">
          <button type="button" class="btn btn-md btn-red-outline mr-3" @click="decline" :disabled="declineForm.loading || approveForm.loading">
            <span v-if="declineForm.loading">Declining</span>
            <span v-else>Decline</span>
          </button>
          <button type="button" class="btn btn-md btn-blue" @click="approve" :disabled="declineForm.loading || approveForm.loading">
            <span v-if="approveForm.loading">Approving</span>
            <span v-else>Approve</span>
          </button>
        </div>

      </div>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="approvalSuccessModal">

      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Expense Request Approved.
      </div>
      <div class="text-xs mb-10">
        The account has been topped up.
      </div>

      <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.approvalSuccessModal.close">
        Okay
      </button>
      
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="declineSuccessModal">

      <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Expense Request Declined!
      </div>
      <!-- <div class="text-xs mb-10">
        The user
      </div> -->

      <button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.declineSuccessModal.close">
        Okay
      </button>
      
    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        request: null,
        requests: this.$options.resource([], {
          columns: [
						{
							name: 'user',
							th: 'Staff',
              render: (request, user) => user.name
						},
						{
							name: 'label',
							th: 'Label'
						},
            {
              name: 'expense_sub_category',
              th: 'Category',
              render: (request, category) => category.label
            },
            {
              name: 'group',
              th: 'Department',
              render: (request, group) => group.name
            },
						{
							name: 'amount',
							th: 'Amount',
              render: (request, amount) => `₦ ${this.$options.filters.currency(amount)}`
						},
            {
              name: 'status',
              th: 'Status',
              render: (request, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="badge badge-green-soft-outline">
                    Approved
                  </div>`;
                case 'declined':
                  return `<div class="badge badge-red-soft-outline">
                    Declined
                  </div>`;
                case 'pending':
                default:
                  return `<div class="badge badge-gray-soft-outline">
                    Pending
                  </div>`;
              }
            }
            },
          ],
        }),
        approveForm: this.$options.basicForm(['credit_limit', 'interest_rate']),
        declineForm: this.$options.basicForm([]),
        checkmark: require('@/assets/checkmark-base.svg'),
        loading: null,
        searchQuery: '',
      }
    },
    computed: {
    },
    beforeMount() {
      this.getRequests();
    },
    mounted() {
      this.$options.listenForClick.apply(this);
    },
    methods: {
      selectRequest(request) {
        this.request = request;
        this.$refs.modal.open();
      },
      comfirmApproval() {
        this.$refs.modal.close();
        this.$refs.approvalConfirmationModal.open();
      },
      comfirmDeclination() {
        this.$refs.modal.close();
        this.$refs.modal.open();
      },


      async getRequests() {
        this.requests.loading = true;
        await this.sendRequest('personal.expenseRequests.all', {
          success: response => {
            this.requests.data = response.data.data;
          }
        });
        this.requests.loading = false;
      },
      async approve() {
        this.approveForm.loading = true;
        await this.sendRequest('personal.expenseRequests.approve', {
          data: {
            expense_request_id: this.request.id,
            status: 'approved'
          },
          success: () => {
            this.$refs.modal.close();
            this.$refs.approvalSuccessModal.open();
            this.getRequests();
            this.request = null;
          },
          error: error => {
            this.approveForm.error = error;
          }
        });
        this.approveForm.loading = false;
      },
      async decline() {
        this.declineForm.loading = true;
        await this.sendRequest('personal.expenseRequests.decline', {
          data: {
            expense_request_id: this.request.id,
            status: 'declined'
          },
          success: () => {
            this.$refs.modal.close();
            this.getRequests();
            this.request = null;
          },
          error: error => {
            this.declineForm.error = error;
          }
        });
        this.declineForm.loading = false;
      },
    }
  }
</script>